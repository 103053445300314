import $ from 'jquery';

export default class LandingPage {
  constructor() {
    this.confirmBtn = document.getElementsByClassName("confirm_btn")[0];
    this.subscribeBtn = document.getElementsByClassName("subscribe_btn")[0];

    this.txtBelowBtn1 = document.getElementsByClassName("text-below-button-t1")[0];
    this.txtBelowBtn2 = document.getElementsByClassName("text-below-button-t2")[0];
    this.landingPageText = document.getElementsByClassName("landing-page-text")[0];
    this.paymentPageText = document.getElementsByClassName("payment-page-text")[0];

    this.transparentBox = document.getElementsByClassName("transparent-box")[0];
    this.query_params = this.extract_params(window.location.href);
    if(this.query_params.action == 'payment_page') {
      this.show_payment_page();
    }
  }

  init() {
    const that = this;
    if(this.subscribeBtn) {
      this.subscribeBtn.addEventListener("click", function(e){
        that.show_payment_page();
      })
    }

    if(this.confirmBtn) {
     this.confirmBtn.addEventListener("click", function(e){
       that.confirmBtn.disabled = true;
     });
    }
  }

  show_payment_page() {
    this.subscribeBtn.disabled = true;
    this.subscribeBtn.style.display = "none";
    this.confirmBtn.style.display = "block";

    if(this.txtBelowBtn1){
      this.txtBelowBtn1.style.display = "none";
    }

    if(this.txtBelowBtn2) {
      this.txtBelowBtn2.style.display = "block";
    }

    if(this.landingPageText) {
      this.landingPageText.style.display = "none";
    }

    if(this.paymentPageText) {
      this.paymentPageText.style.display = "block";
    }

    if(this.transparentBox) {
      this.transparentBox.style.display = "none";
    }
  }

  hide_payment_page() {
    this.subscribeBtn.disabled = false;
    this.subscribeBtn.style.display = "block";
    this.confirmBtn.style.display = "none";

    if(this.txtBelowBtn1){
      this.txtBelowBtn1.style.display = "block";
    }

    if(this.txtBelowBtn2) {
      this.txtBelowBtn2.style.display = "none";
    }

    if(this.landingPageText) {
      this.landingPageText.style.display = "block";
    }

    if(this.paymentPageText) {
      this.paymentPageText.style.display = "none";
    }

    if(this.transparentBox) {
      this.transparentBox.style.display = "block";
    }
  }

  extract_params(url) {
    let params_string = url.split('?')[1];
    let params = new URLSearchParams(params_string);
    return Object.fromEntries(params.entries());
  }

  append_anti_fraud_script_to_head(js) {
    let antiFraudScript = document.createElement("script");
    antiFraudScript.setAttribute('type', 'text/javascript');
    antiFraudScript.text = js
    document.head.appendChild(antiFraudScript);
  }

  ajax_invite(url) {
    let that = this;
    url = url.replace(/^http:\/\//, 'https://');

    $.ajax({
      method: "GET",
      url: url,
      contentType: "application/json"
    }).done(function( data ) {
      console.log(data);
      if (data.intent === "show_payment_page") {
        that.show_payment_page();
        if(data.intent_data.anti_fraud_script) {
          that.append_anti_fraud_script_to_head(data.intent_data.anti_fraud_script);
        }
      } else if (data.intent === "open_sms_composer") {
        that.hide_payment_page();
        window.open(data.intent_data.sms_href);
        return;
      } else {
        that.hide_payment_page()
        window.location = data.redirect_url;
      }
    });
  }
}
